/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat
@import 'bluechip-theme'

body
    margin: 0px
    font-family: 'Open Sans', sans-serif
    font-size: 14px

main
  min-height: 600px
  .col
    &:hover
      transition: 0.3s

section
  margin: auto
  align-self: center
  max-width: 100%
  padding: 10px
  

@media only screen and (min-width: 1000px)
  section
    max-width: 80%

@media only screen and (min-width: 1200px)
  section
    max-width: 75%

// Definition der Footer-background color normal an Dark Mode 
body 
  --footer-background-color: #{$light-bg-darker-20}
  --lightergray-color: #e9e9e9
  --greyish-mark: #e4e4e4
body.darkMode 
  --footer-background-color: #{$dark-bg-lighter-20}
  --lightergray-color: #707070
  --greyish-mark: #666666
footer
  background-color: var(--footer-background-color)
  border-top: 4px solid map-get($mat-accent, main)
  border-bottom: 4px solid map-get($mat-primary, main)

header section mat-toolbar
  border-bottom: 4px solid map-get($mat-accent, main)

bc-header mat-drawer-container mat-drawer .open-menu-toolbar
    border-bottom: 4px solid map-get($mat-accent, main)
 
.navigateable:hover
    background-color: map-get($mat-accent, main)

.form-container mat-form-field 
  margin-right: 8px

.form-container mat-form-field 
  width: 220px